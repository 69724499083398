<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form
                    ? "Create New Building"
                    : "Update Building"
                }}
              </strong>
              <div v-if="$route.name != route.form" class="card-header-actions">
                <b-button size="sm"
                          variant="danger"
                          v-b-tooltip.hover
                          title="Delete Building"
                          @click="handleDelete">
                  Delete
                </b-button>
              </div>
            </div>
            <b-row>
              <!--building-->
              <b-col sm="12">
                <form-group :validator="$v.form.name"
                            label="Name"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter name"
                                autocomplete="off"
                                v-model="form.name"></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm">Back</b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                {{ $route.name == route.form ? "Submit" : "Save Changes" }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    required,
  } from "vuelidate/lib/validators";

  export default {
    data: () => ({
      route: {
        form: "BuildingManagementCreate",
        table: "BuildingManagement",
      },
      form: {
        name: null,
      },
    }),
    validations: {
      form: {
        name: { required },
      },
    },
    created() {
      const self = this;
      if (self.$route.name != self.route.form) {
        this.get();
      }
    },
    methods: {
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/buildings/${self.$route.params.id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                name: response.data.name,
              };
            }
            loader.hide();
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this building. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "/buildings";
        } else {
          _confirmText = "You are about to update this building. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          _url = `/buildings/${self.$route.params.id}`;
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
      handleDelete() {
        const self = this;

        self.$dialog
          .confirm("You are about to delete this building. Are you sure ?", {
            okText: "Yes, Delete",
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/remove", {
                url: `/buildings/${self.$route.params.id}`,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
    },
  };
</script>
